//
import store from 'reducers/store'

interface UpgradePlanURLProps {
    planId?: string
}

export const upgradePlanURL = ({ planId }: UpgradePlanURLProps = {}) => {
    const {
        app: { current_user = {} },
    } = store.getState()

    let path = `/ratesmgr/profiles/${current_user.id}/edit?`

    if (current_user.can_change_plan) {
        path += 'section=pricing_plans'
        if (planId) path += `&upgrade_plan_id=${planId}`
    } else {
        path += 'section=billing'
    }

    return path
}

export const billingURL = () => {
    const userId = store.getState().app.current_user.id

    return `/ratesmgr/profiles/${userId}/edit?section=billing`
}

export const privacyAccountUrl = () => {
    const {
        app: { current_user = {} },
    } = store.getState()

    return `/ratesmgr/profiles/${current_user.id}/edit#edit-password`
}

export const addOrUpdateUrlParameter = (url, key, value) => {
    const parts = url.split('?')
    const baseUrl = parts[0]
    const query = parts[1]
    const queryParams = {}

    if (query) {
        query.split('&').forEach((param) => {
            const [k, v] = param.split('=')
            queryParams[k] = v
        })
    }

    // Add or update the key-value pair.
    queryParams[key] = encodeURIComponent(value)

    // Build the new query string.
    const newQuery = Object.keys(queryParams)
        .map((k) => `${k}=${queryParams[k]}`)
        .join('&')

    return `${baseUrl}?${newQuery}`
}
