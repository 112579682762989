import { createBrowserHistory } from 'history'

const browserHistory = createBrowserHistory({
    /* pass a configuration object here if needed */
})

let previous = 0
let previousURL = []

const _push = browserHistory.push

browserHistory.push = (path, state) => {
    previous += 1
    previousURL.push(path)
    _push(path, state)
}

/**
 * navigates back if previous url == fallbackURL or navigates to the specified url
 * @param fallbackURL
 */
browserHistory.goToOrBack = (fallbackURL = undefined) => {
    if (
        previous > 0 &&
        previousURL[previousURL.length - 1].includes(fallbackURL)
    ) {
        previous -= 1
        previousURL.pop()
        browserHistory.goBack()
    } else {
        browserHistory.push(fallbackURL)
    }
}

export default browserHistory
