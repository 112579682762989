//
import React, { Component, createContext, useContext } from 'react'
import {
    PORTAL_MESSAGES,
    createPortal,
    removePortal,
} from 'reducers/modules/dashboard'
import { connect } from 'react-redux'
import cx from 'classnames'
import history from 'components/frontend/history'
import { addOrUpdateUrlParameter } from 'utils/urls'

/**
 * Modal Properties
 */

const ModalContext = createContext({
    hide: () => {},
    show: () => {},
    onClose: () => {},
    onBack: () => {},
})

class Modal extends Component {
    state = {
        addIn: false,
        hidden: false,
    }

    static defaultProps = {
        captureNotifications: true,
        hideBackdrop: false,
        skipOverflowHidden: false,
        createPortal: () => {},
        removePortal: () => {},
        hidden: false,
    }

    messagesRef

    constructor(props) {
        super(props)

        if (props.hidden) {
            this.state.hidden = props.hidden
        }
    }

    componentDidMount() {
        this.messagesRef = React.createRef()
        const { modalName, skipURLRewrite } = this.props

        this.updateBody(this.state.hidden)

        this.setState({
            addIn: true,
        })

        const { captureNotifications } = this.props
        if (captureNotifications) {
            this.props.createPortal(PORTAL_MESSAGES, this.messagesRef)
        }

        if (modalName && !skipURLRewrite) {
            let path = location.href.replace(location.origin, '')
            path = addOrUpdateUrlParameter(path, 'ul', modalName)
            if (location.hash) {
                path += location.hash
            }
            history.push(path)
        }
    }

    componentWillUnmount() {
        const { modalName, captureNotifications } = this.props

        this.updateBody(true)

        if (captureNotifications) {
            this.props.removePortal(PORTAL_MESSAGES)
        }

        if (modalName) {
            let path = `?ul=`
            if (location.search && location.search.includes(path)) {
                let newPath = location.pathname + '?'
                if (location.hash) {
                    newPath += location.hash
                }
                history.push(newPath)
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.hidden !== this.props.hidden) {
            this.setState({ hidden: this.props.hidden })
            this.updateBody(this.props.hidden)
        }

        const { hidden } = this.state
        if (hidden !== prevState.hidden && document.body) {
            this.updateBody(hidden)
        }
    }

    updateBody(hidden) {
        if (this.props.skipOverflowHidden) return

        if (hidden) {
            document.body.classList.remove('modal-open')
        } else {
            document.body.classList.add('modal-open')
        }
    }

    getContext() {
        return {
            hide: () => {
                this.setState({ hidden: true })
            },
            show: () => {
                this.setState({ hidden: false })
            },
            onClose: () => {
                this.props.onClose && this.props.onClose()
            },
        }
    }

    render() {
        const {
            id,
            title,
            children,
            onClose,
            className,
            closeButtonClass = '',
            contentWrapClass,
            showCloseButton = true,
            hideBackdrop,
            onBack,
            modalCloseButtonId,
            modalBackButtonId,
        } = this.props
        const { addIn, hidden } = this.state
        const inClass = addIn ? ' in' : ''

        return (
            <ModalContext.Provider value={this.getContext()}>
                <div
                    id={id ? id : 'settings-modal'}
                    className={'modal fade' + inClass}
                    data-backdrop="static"
                    aria-hidden="false"
                    style={{ display: hidden ? 'none' : 'block' }}
                    data-testid="modal"
                    role="dialog"
                    aria-modal="true"
                >
                    <div
                        className={cx(
                            'modal-dialog',
                            contentWrapClass ? contentWrapClass : 'mbasic'
                        )}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div
                            className={className ? className : 'modal-content'}
                        >
                            {showCloseButton && (
                                <button
                                    id={
                                        modalCloseButtonId ?? 'modalCloseButton'
                                    }
                                    data-testid="modal-close-button"
                                    type="button"
                                    className={cx(closeButtonClass, 'close')}
                                    data-dismiss="modal"
                                    aria-hidden="true"
                                    onClick={onClose}
                                />
                            )}
                            {title && <h3 className="modal-title">{title}</h3>}
                            {onBack && (
                                <button
                                    id={modalBackButtonId ?? 'modalBackButton'}
                                    data-testid="modal-close-button"
                                    type="button"
                                    className="back"
                                    data-dismiss="modal"
                                    aria-hidden="true"
                                    onClick={onBack}
                                />
                            )}
                            <div ref={this.messagesRef} />
                            {children}
                        </div>
                    </div>
                </div>
                {hideBackdrop || hidden ? null : (
                    <div className={'modal-backdrop fade' + inClass} />
                )}
            </ModalContext.Provider>
        )
    }
}

export default connect(() => ({}), { createPortal, removePortal })(Modal)

export function useModal() {
    return useContext(ModalContext)
}
